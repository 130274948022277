var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"detail",staticClass:"detail"},[_c('van-swipe',{staticClass:"detail-1",attrs:{"autoplay":30000,"indicator-color":"white"},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('div',{staticClass:"custom-indicator"},_vm._l((_vm.detail.banner_img),function(item,index){return _c('div',{key:index,class:index === _vm.current && 'active',style:(index === _vm.current &&
							index === 0 && {
								'background-image': ("url(" + (require('@/assets/detail/indicator.png')) + ")"),
							})})}),0)]},proxy:true}])},_vm._l((_vm.detail.banner_img),function(item,index){return _c('van-swipe-item',{key:index,style:({
					'background-image': ("url(" + item + ")"),
				})})}),1),_c('div',{staticClass:"detail-2"},[_c('div',[_vm._v(_vm._s(_vm.detail.name))]),_c('div',[_vm._v("￥"+_vm._s(_vm.detail.price))])]),_c('div',{staticClass:"detail-3"},[_c('Cell',{attrs:{"info":{
					title: '描述',
					value: _vm.detail.remark,
				}}})],1),_c('div',{staticClass:"detail-4",domProps:{"innerHTML":_vm._s(_vm.detail.desc)}}),_c('div',{staticClass:"detail-5"},[_vm._v("—— 已经到底了——")]),(_vm.detail.id)?_c('div',{staticClass:"purchase"},[_c('div',{on:{"click":_vm.handleBuy}},[_vm._v("立即加盟")])]):_vm._e(),_c('Scroll2Top',{staticClass:"top"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }