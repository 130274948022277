<template>
	<div class="my-team">
		<div class="my-team-1" ref="card">
			<Card :info="info" />
		</div>

		<van-collapse
			:border="false"
			@change="handleChange"
			v-model="active"
			accordion
		>
			<van-collapse-item :border="false" :is-link="false" ref="recommend">
				<template #title>
					<van-icon
						name="play"
						class="icon"
						:class="active === 0 && 'select'"
					/>
					<span class="title">我的推荐人（{{info.parent_data.lenght > 0 ? 1 : 0}}）</span>
				</template>
				<div class="content"  v-if="info.parent_data.lenght > 0">
					<img :src="info.parent_data && info.parent_data.avatar" />
					<div>
						<div>{{ info.parent_data && info.parent_data.realname }}</div>
						<div>
							粉丝 : {{ info.parent_data && info.parent_data.fans_count }}
						</div>
					</div>
				</div>
			</van-collapse-item>
			<van-collapse-item :border="false" :is-link="false" ref="fans">
				<template #title>
					<van-icon
						name="play"
						class="icon"
						:class="active === 1 && 'select'"
					/>
					<span class="title">我的粉丝（{{ fansInfo.total }}）</span>
				</template>
				<div
					:style="{
						'max-height': wrapperHeight + 'px',
					}"
					class="overflow"
					@scroll="handleScroll"
				>
					<div class="content" v-for="item in fansInfo.data" :key="item.id">
						<img :src="item.avatar" />
						<div>
							<div>{{ item.realname }}</div>
							<div>粉丝 : {{ item.fans_count }}</div>
						</div>
					</div>
				</div>
			</van-collapse-item>
		</van-collapse>
	</div>
</template>

<script>
	import { $get } from "@/utils/request.js";
	import Card from "./Card.vue";
	import { Collapse, CollapseItem, Icon } from "vant";
	export default {
		name: "myTeam",
		components: {
			Card,
			vanCollapse: Collapse,
			vanCollapseItem: CollapseItem,
			vanIcon: Icon,
		},
		data() {
			return {
				active: 0,
				info: this.$route.params.info,
				fansInfo: {
					data: [],
					current_page: 1,
					total: 0,
				},
				wrapperHeight: 0,
				scrollBol: false,
			};
		},
		created() {
			if (!this.info) {
				this.info = {};
				$get("/home/getUserTeamData").then((res) => {
					this.info = res;
				});
			}

			this.upDateFansInfo();
		},
		methods: {
			handleChange() {
				const { card, recommend, fans } = this.$refs;
				this.wrapperHeight =
					document.documentElement.clientHeight -
					(card.clientHeight +
						recommend.$el.querySelector(".van-collapse-item__title")
							.clientHeight +
						fans.$el.querySelector(".van-collapse-item__title").clientHeight);
			},
			handleScroll(e) {
				if (this.scrollBol) return;
				const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
				if (scrollHeight - scrollTop - clientHeight < 5) {
					this.scrollBol = true;
					// 滚动条触底;
					if (this.fansInfo.lastPage > this.fansInfo.current_page) {
						this.fansInfo.current_page += 1;
						this.upDateFansInfo();
					}
				}
			},
			upDateFansInfo() {
				$get("/home/getUserTeamList", { page: this.fansInfo.current_page }).then(
					(res) => {
						this.fansInfo = {
							...this.fansInfo,
							last_page: res.last_page,
							current_page: res.current_page,
							total: res.total,
						};

						this.fansInfo.data.push(...res.data);
						this.scrollBol = false;
					}
				);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.my-team {
		height: 100%;
		background: #f2f5fc;

		&-1 {
			padding: 15px;
		}

		.icon {
			left: -3px;
			bottom: 1.5px;
			transition: 0.5s;
			color: #808080;
			font-size: 18px;
		}

		.icon.select {
			transform: rotateZ(90deg);
			color: #a78851;
		}

		.title {
			margin-left: 3px;
			color: #000000;
			font-size: 16px;
		}

		.content {
			width: 375px;
			height: 70px;
			padding: 10px 35px;
			box-sizing: border-box;

			> img {
				float: left;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background-color: rgba(0, 0, 0, 0.5);
			}

			> div {
				width: 245px;
				float: left;
				margin-left: 10px;
				padding-top: 6px;

				div {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					line-height: 100%;
				}

				div:nth-of-type(1) {
					font-size: 16px;
					color: #000000;
				}

				div:nth-of-type(2) {
					margin-top: 8px;
					font-size: 14px;
					color: #808080;
				}
			}
		}

		.overflow {
			overflow: scroll;
			-webkit-overflow-scrolling: touch;
		}
	}
</style>