<template>
	<div class="wallet">
		<Card1 :item="flogLogStatics" />
		<!-- <Card2 :item="flogLogStatics" /> -->
		<!-- <Card3 :item="flogLogStatics" /> -->
		<!-- <van-cell is-link class="wallet-1" :to="{ name: 'myBankCard', query: { addBtnBol: flogLogStatics.bank_num != flogLogStatics.bank_max_num } }">
			<template #title>
				<img :src="require('@/assets/wallet/wallet.png')" />
				<span>我的银行卡（{{ flogLogStatics.bank_num || 0 }}/{{ flogLogStatics.bank_max_num }}）</span>
			</template>
		</van-cell> -->
	</div>
</template>


<script>
    import { $get } from "@/utils/request.js";
	import Card1 from "./Card1.vue";
	import Card2 from "./Card2.vue";
	import Card3 from "./Exchange.vue";
	import { Cell } from "vant";
	export default {
		name: "wallet",
		components: {
			Card1,
			Card2,
			Card3,
			vanCell: Cell,
		},
		data() {
			return {
				flogLogStatics: {},
			}
		},
		created() {
			this.getFlogLogStatics()
		},
		methods: {
			// 获取收益统计
			getFlogLogStatics() {
				$get('/home/flogLogStatics').then(res => {
					this.flogLogStatics = res
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	.wallet {
		min-height: 100%;
		background: #f2f5fc;
		box-sizing: border-box;
		padding-top: 10px;

		.card1 {
			margin-left: 10px;
		}

		.card2 {
			margin: -7px 0 0 10px;
		}

		&-1 {
			width: 345px;
			margin: auto;
			border-radius: 8px;
			padding: 15px 10px;
			align-items: center;
			font-size: 0;
			line-height: 100%;

			img {
				width: 30px;
				height: 30px;
			}

			span {
				margin-left: 7px;
				font-size: 16px;
				color: #000000;
			}

			.van-cell__title {
				display: flex;
				align-items: center;
			}
		}
	}
</style>