// 微信公众号相关配置
import CONFIG from '@/config/'
import router from '@/router';
// eslint-disable-next-line
import { $get, $post } from '@/utils/request'

// 微信全局变量
const wx = window.wx

// 微信公众号网页授权登陆换取code
const $wxLogin = () => {
  const url = CONFIG.apiHost
  const wxAuthorizeUrl = `${url}/wechat/auth?callback_url=${encodeURI(location.href)}`
  window.location.href = wxAuthorizeUrl
}

// 获取登陆token
const mpLogin = () => {
  return new Promise((resolve, reject) => {
    $post('/login/wechat').then(res => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    });
  })
}

// 微信公众号获取签名信息
const fetchWXSignature = () => {
  let url = location.href.split('#')[0]
  return new Promise((resolve, reject) => {
    $get('/home/wxsdk', { url }).then(res => {
      console.log('微信签名信息：', res)
      $wxConfig(res)
      // resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// 微信公众号签名配置
const $wxConfig = serverJson => {
  let { appId, timestamp, nonceStr, signature } = serverJson
  wx.config({
    debug: false,
    appId: appId, // 必填，公众号的唯一标识
    timestamp: timestamp, // 必填，生成签名的时间戳
    nonceStr: nonceStr, // 必填，生成签名的随机串
    signature: signature, // 必填，签名，见附录1
    jsApiList: [
      // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      'checkJsApi',
      'chooseWXPay'
    ]
  })
}

// 微信公公众微信支付
const $wxPay = wxPayParams => {
  /* eslint-disable-next-line */
  let { timestamp, nonceStr, signType, paySign } = wxPayParams
  return new Promise((resolve, reject) => {
    wx.chooseWXPay({
      timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      nonceStr, // 支付签名随机串，不长于 32 位
      package: wxPayParams.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      paySign, // 支付签名
      success: res => {
        console.log(res, '支付成功后的回调函数!!!!!!!!!!!!!!')
        // 支付成功后的回调函数
        if (res.errMsg === 'chooseWXPay:ok') {
          resolve(res)
        } else {
          reject(res)
        }
      },
      fail: err => {
        // 支付失败后的回调函数
        reject(err)
      },
      cancel: cancelRes => {
        reject(cancelRes)
      }
    })
  })
}

export {
  fetchWXSignature,
  $wxPay,
  $wxLogin,
  mpLogin
}

