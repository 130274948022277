<template>
	<div class="product">
		<div v-if="items.length > 0">
			<div
				class="product-1"
				:style="{
					'background-image': `url(${require('@/assets/product/bg.png')})`
				}"
				v-for="(item, index) in items"
				:key="index"
			>
				<div class="left">
					<div class="product-1-1">{{ item.type_name }}</div>
					<div class="product-1-2">{{ item.remark }}</div>
					<div class="product-1-3">有效期至：{{ item.time_end.substr(0, item.time_end.lastIndexOf(' ')) }}</div>
				</div>
				<div class="right">
					<div class="product-2-1">¥</div>
					<div class="product-2-2">{{ formatCurrency(item.acount) }}</div>
					<div class="product-2-3">元</div>
				</div>
			</div>
		</div>
		<div v-else><h1 class="none">暂无产品券</h1></div>
		<div class="bottom">注:以上仅供参考</div>
	</div>
</template>

<script>
import { $get } from '@/utils/request.js'
export default {
	data() {
		return {
			items: []
		}
	},
	created() {
		this.getUserProductInfo()
	},
	methods: {
		// 产品券信息
		getUserProductInfo() {
			$get('/home/product_coupon/my_product_coupon_list').then(res => {
				this.items = res
			})
		},
		formatCurrency(num) {
			const re = /(?=(?!(\b))(\d{3})+$)/g
			return num.replace(re, ',')
		}
	}
}
</script>

<style lang="scss" scoped>
.product {
	min-height: 100%;
	box-sizing: border-box;
	padding: 0 24px;
	padding-top: 10px;
	.none {
		font-size: 23px;
		font-weight: 300;
		text-align: center;
	}
	.bottom {
		font-size: 0.3em;
		text-align: center;
		position: absolute;
		bottom: 15px;
		color: #969292;
		left: 40%;
	}
	.left {
		padding: 5% 0 0 5%;
		width: 40%;
		color: #5f3b24;
		font-size: 18px;
	}
	.right {
		display: flex;
		width: 155px;
		position: relative;
		line-height: 91px;
	}
	&-1 {
		width: 100%;
		height: 91px;
		background-repeat: no-repeat;
		display: flex;
		background-size: contain;
		padding-bottom: 20px;
		&-1 {
			color: #5f3b24;
			font-size: 18px;
			font-weight: bold;
			padding-bottom: 3px;
		}

		&-2 {
			color: #8f6245;
			font-size: 11px;
			opacity: 0.8;
		}
		&-3 {
			color: #8f6245;
			font-size: 11px;
			opacity: 0.8;
		}
	}

	&-2 {
		display: flex;
		&-1 {
			font-size: 16px;
			font-weight: 300;
			color: #5f3b24;
			padding-top: 10px;
			float: left;
		}

		&-2 {
			height: 65px;
			font-size: 38px;
			font-family: Roboto-Black, Roboto;
			font-weight: 900;
			line-height: 91px;
			text-shadow: 0px 1px 4px rgba(95, 59, 36, 0.3);
			background: linear-gradient(270deg, #99602f 0%, #d29360 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		&-3 {
			font-weight: 300;
			font-size: 16px;
			color: #5f3b24;
			padding-top: 8px;
			float: right;
		}
	}
}
</style>
