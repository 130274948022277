<template>
	<div class="detail">
		<div
			class="content"
			:style="{
				'background-image': `url(${require('@/assets/dividend/bg.png')})`,
			}"
		>
			<img
				class="content-1"
				:src="require('@/assets/other/plosticFreeEarch.png')"
			/>
			<div class="content-2">分红券证书</div>
			<div class="content-3">
				<span>{{ name }}</span>
				<span>先生/女士</span>
			</div>
			<div class="content-4">无塑地球赠送您<span> {{ item.stock_num }} </span>分红券</div>
			<div class="content-5">特此证明</div>
			<div class="content-6">广州无塑地球新材料科技有限公司</div>
			<div class="content-7">
				<span>{{ this.time[0] }}</span>年<span>{{ this.time[1] }}</span>月<span>{{ this.time[2] }}</span>日
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				item: {},
				time: []
			}
		},
		created() {
			this.item = JSON.parse(this.$route.query.item)
			this.name = this.$route.query.name
			this.time = this.item.created_at.split(' ')[0].split('-')
		}
	};
</script>

<style lang="scss" scoped>
	.detail {
		width: 100%;
		height: 100%;
		position: relative;

		.content {
			width: 345px;
			height: 480px;
			background-size: 100% 100%;
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 50px;
			left: 0;
			right: 0;

			> img,
			> div {
				position: absolute;
				line-height: 100%;
			}

			&-1 {
				width: 72px;
				top: 72px;
				left: 48px;
			}

			&-2 {
				width: 100%;
				text-align: center;
				top: 116px;
				font-size: 20px;
				font-weight: bold;
			}

			&-3,
			&-4,
			&-5,
			&-6,
			&-7 {
				font-size: 14px;
				color: #333132;
			}

			&-3 {
				top: 159px;
				left: 46px;

				span:nth-of-type(1) {
					display: inline-block;
					text-decoration: underline;
				}

				span:nth-of-type(2) {
					margin-left: 10px;
				}
			}

			&-4 {
				top: 195px;
				left: 73px;

				span {
					display: inline-block;
					padding: 0 6px;
					border-bottom: 2px solid #000;
				}
			}

			&-5 {
				top: 215px;
				left: 73px;
			}

			&-6 {
				top: 341px;
				right: 44px;
			}

			&-7 {
				top: 361px;
				right: 44px;

				span {
					display: inline-block;
					padding: 0 6px;
					border-bottom: 2px solid #000;
				}

        span:nth-of-type(1) {
          padding: 0 10px;
        }
			}
		}
	}
</style>