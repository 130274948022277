<template>
  <div
    class="my-card"
    :style="{
      'background-image': `url(${require('@/assets/my/bg.png')})`,
    }"
  >
    <div class="my-card-1">
      <img
        :src="userInfo.avatar"
        class="img"
      />
      <div class="content">
        <div class="name">
          <div>{{ userInfo.realname || userInfo.name }}</div>
          <van-icon
            v-if="userInfo.is_wechat_update"
            name="replay"
            @click="handleUpdate"
          />
        </div>
        <div class="verification">
          <template v-if="userInfo.user_type">
            <!-- 企业用户 -->
            <div v-if="userInfo.icheck">已认证</div>
            <!-- <router-link
              v-else
              :to="{ name: 'authenticationEnterprise' }"
              custom
              v-slot="{ navigate }"
            > -->
            <div
              @click="getCheckUserAudit"
              v-else
            >前往认证</div>
            <!-- </router-link> -->
          </template>

          <template v-else>
            <!-- 个人用户 -->
            <div v-if="userInfo.icheck">已实名</div>
            <!-- <router-link
              v-else
              :to="{ name: 'authenticationIdentity' }"
              custom
              v-slot="{ navigate }"
            > -->
            <div
              @click="getCheckUserAudit"
              v-else
            >前往认证</div>
            <!-- </router-link> -->
          </template>
        </div>
      </div>
      <router-link
        custom
        :to="{ name: 'information' }"
        v-slot="{ navigate }"
      >
        <div
          class="detailed"
          @click="navigate"
        >
          <span>详情</span>
          <van-icon name="arrow" />
        </div>
      </router-link>
    </div>
    <div class="my-card-2">
      <div>
        <div class="count">{{ userInfo.now_money }}</div>
        <div class="content">总资产</div>
      </div>
      <div>
        <div class="count">{{ userInfo.brokerage_price }}</div>
        <div class="content">佣金</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "vant";
import { mapState, mapMutations } from "vuex";
import { $get, $post } from "@/utils/request.js";
export default {
  data() {
    return {
      isClick: true // 是否可点击
    };
  },
  components: {
    vanIcon: Icon
  },
  computed: {
    ...mapState(["userInfo"])
  },
  created() {
    
  },
  methods: {
    ...mapMutations(["setUserInfo"]),

    // 用户信息接口
    getUserInfo() {
      $get("/home/getUserInfo").then(res => {
        this.setUserInfo(res);
      });
    },

    // 更新微信用户信息
    handleUpdate() {
      if (this.isClick) {
        this.isClick = false;
        $post("/home/updateWechatInfo")
          .then(res => {
            this.$toast("更新成功");
            this.isClick = true;
            this.getUserInfo();
          })
          .catch(err => {
            this.isClick = true;
          });
      }
    },

    // 检查用户/企业是否已实名认证
    getCheckUserAudit() {
		this.$toast("功能开发中");
		return;
      if (this.isClick) {
        this.isClick = false;
        $get("/home/checkUserAudit")
          .then(res => {
            if (res.length) {
              // 去实名认证
              localStorage.setItem("wxLoginStatus", true);
              window.location.href = res;
            } else {
              // 去企业认证
              this.$router.push({ name: "authenticationEnterprise" });
            }
            this.isClick = true;
          })
          .catch(err => {
            this.isClick = true;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.my-card {
  width: 375px;
  height: 176px;
  background-size: 100% 100%;

  &-1 {
    height: 80px;
    padding: 13px;

    > div,
    > img {
      float: left;
      height: 100%;
    }

    .img {
      width: 80px;
      border-radius: 50%;
    }

    .content {
      box-sizing: border-box;
      width: 204px;
      margin-left: 10px;
      padding-top: 16px;

      .name {
        color: #ffffff;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;

        div {
          max-width: 160px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        i {
          margin-left: 10px;
        }
      }

      .verification {
        height: 30px;
        margin-top: 19px;
        overflow: scroll;
        white-space: nowrap;
        font-size: 0;

        div {
          display: inline-block;
          height: 22px;
          border-radius: 100px;
          line-height: 23px;
          padding: 0 17px;
          padding: 0 12px;
          font-size: 12px;
          color: #ffffff;
        }

        div:nth-of-type(1) {
          background-color: #29b7ff;
        }

        div:nth-of-type(2) {
          margin-left: 10px;
          background-color: #ffaa44;
        }
      }
    }

    .detailed {
      width: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;

      i {
        margin-top: 3px;
        right: -4px;
      }
    }
  }

  &-2 {
    margin-top: 15px;
    height: 41px;
    display: flex;

    > div {
      width: 187px;
      height: 100%;
      text-align: center;
    }

    > div:nth-of-type(1) {
      border-right: 1px solid rgba($color: #fff, $alpha: 0.3);
    }

    .count {
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    .content {
      font-size: 14px;
      color: #ffffff;
      opacity: 0.8;
    }
  }
}
</style>