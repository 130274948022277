<template>
	<div class="information">
		<UpLoad url="/home/saveAvatar" parameter="avatar" @finish="uploadFinish">
			<van-cell class="information-1" is-link value="修改头像" :clickable="false">
				<template #title>
					<img :src="userInfo.avatar" />
				</template>
			</van-cell>
		</UpLoad>

		<van-cell
			class="information-2"
			is-link
			title="昵称"
			:value="userInfo.nickname"
			:to="{
				name: 'informationRevise',
				params: {
					data: userInfo.nickname,
					url: '/home/saveName',
					key: 'nickname',
					method: nameMethod
				}
			}"
		/>

		<van-cell class="information-3" title="手机号" :value="userInfo.phone" :clickable="false" />

		<van-cell class="information-2 red" is-link :border="false" title="实名" value="还未实名，前往认证" v-if="!userInfo.icheck" @click="getCheckUserAudit" />

		<template v-else>
			<van-cell class="information-3" title="实名" :value="userInfo.realname" :clickable="false" />

			<van-cell class="information-3" title="身份证号码" :value="userInfo.card_no" :clickable="false" />

			<!-- <van-cell
				class="information-2"
				is-link
				:border="false"
				title="提现密码"
				value="设置/修改提现密码"
				:clickable="false"
			/> -->
		</template>


    <van-cell-group style="margin-top:50px" v-if="userInfo.audit_fail_reason !== ''">
      <van-cell title="" :label="userInfo.audit_fail_reason"></van-cell>
    </van-cell-group>
	</div>
</template>

<script>
import { Cell } from 'vant'
import { mapState, mapMutations } from 'vuex'
import UpLoad from '@/components/UpLoad.vue'
import { $get } from '@/utils/request.js'
export default {
	name: 'information',
	components: {
		vanCell: Cell,
		UpLoad
	},
	data() {
		return {
			isClick: true // 是否可点击
		}
	},
	computed: {
		...mapState(['userInfo'])
	},
	created() {
		let res = localStorage.getItem('userInfo')
		if (res) {
			this.userInfo = JSON.parse(res)
			localStorage.removeItem('userInfo')
		}
	},
	methods: {
		...mapMutations(['setUserInfo']),
		nameMethod(val) {
			this.setUserInfo({
				...this.userInfo,
				name: val
			})
		},

		// 上传头像完成
		uploadFinish(e) {
			this.setUserInfo({
				...this.userInfo,
				avatar: e
			})
		},

		// 检查用户/企业是否已实名认证
		getCheckUserAudit() {
			this.$toast("功能开发中");
			return;
			if (this.isClick) {
				this.isClick = false
				localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
				$get('/home/checkUserAudit')
					.then(res => {
						if (res.length) {
							// 去实名认证
							localStorage.setItem('wxLoginStatus', true)
							window.location.href = res
						}
						this.isClick = true
					})
					.catch(err => {
						this.isClick = true
					})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.information {
	min-height: 100%;
	background: #f2f5fc;

	.van-cell__title,
	.van-cell__value {
		font-size: 16px;
	}

	.van-cell__title {
		color: #000000;
	}

	&-1 {
		width: 100vw;
		box-sizing: border-box;
		padding: 12px 12px 12px 15px;
		.van-cell__value,
		.van-icon {
			line-height: 60px;
		}

		.van-cell__title {
			height: 60px;

			img {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background-color: rgba(0, 0, 0, 0.5);
			}
		}
	}

	&-2 {
		padding: 12px 12px 12px 15px;
	}

	&-3 {
		padding: 12px 15px;

		.van-cell__value {
			color: #000000;
		}
	}

	.red {
		.van-cell__value {
			color: #00a63a;
		}
	}
}
</style>
