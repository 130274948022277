<template>
	<div class="my">
		<MyCard />
		<!-- <OrderCard class="my-2" /> -->
		<ApplicationCard class="my-3" />

		<!-- 存在待签署合同 - 提示签署合同弹框 -->
		<div class="unsignBox" v-if="unsignBoxBol">
			<div class="unsignMain">
				<div class="unsignContent">
					<img class="tipsIcon" src="@/assets/my/tipsIcon.png" />
					<p class="tips">您还有待签约的合同</p>
					<button class="btn" @click="toContract">前往签约</button>
				</div>
				<img class="closeBtn" src="@/assets/other/remind2.png" @click="unsignBoxBol = false" />
			</div>
		</div>
		<!-- 存在待签署合同 - 提示签署合同弹框 -->
		<div class="unsignBox" v-if="outOfStock">
			<div class="unsignMain">
				<div class="unsignContent">
					<img class="OfStockIcon" src="@/assets/other/ofstock.png" />
					<p class="hints">您有设备已缺袋!</p>
					<p class="hintsOnclick">了解具体请点击 "查看详情"</p>
					<button class="btn" @click="toOfStock">查看详情</button>
				</div>
				<img class="closeBtn" src="@/assets/other/remind2.png" @click="outOfStock = false" />
			</div>
		</div>
	</div>
</template>

<script>
import { $get, $post } from '@/utils/request.js'
import MyCard from './MyCard.vue'
import OrderCard from './OrderCard.vue'
import ApplicationCard from './ApplicationCard.vue'
import { mapMutations } from 'vuex'
import { Dialog } from 'vant'
export default {
	name: 'my',
	components: {
		MyCard,
		OrderCard,
		ApplicationCard
	},
	data() {
		return {
			unsignBoxBol: false, // 提示签署弹框 显示/隐藏
			outOfStock: false // 提示缺货弹框 显示/隐藏
		}
	},
	created() {
		if (this.token) {
			this.getUserInfo()
			this.getOfStock()
		}
	},
	computed: {
		token() {
			return localStorage.getItem('token')
		}
	},
	watch: {
		token(str) {
			if (str) {
				this.getOfStock()
				this.getUserInfo()
			}
		}
	},
	methods: {
		...mapMutations(['setUserInfo']),
		// 用户信息接口
		getUserInfo() {
			$get('/home/getUserInfo').then(res => {
				console.log('getUserInfo===' + JSON.stringify(res))
				// this.unsignBoxBol = res.contract_details_unsign >= 1 ? true : false
				this.setUserInfo(res.user)
				if (res.is_modal) {
					Dialog({ message: res.modal_text })
				}
			})
		},
		// 判断是否有未阅读的缺货列表
		getOfStock() {
			$post('/home/machine/checkMachineAlam')
				.then(res => {
					//等于1的时候 有，就是要显示弹框
					this.outOfStock = res.have_unread_alarm === 1 ? true : false
					console.log('this.outOfStock=' + this.outOfStock)
				})
				.catch(error => {})
		},
		// 跳转我的合同
		toContract() {
			this.$router.push({ name: 'contract' })
		},
		// 跳转缺货提示
		toOfStock() {
			//更新阅读状态
			$post('/home/machine/isReadUpdate').then(res => {
				console.log('已更新阅读' + JSON.stringify(res))
			})
			this.$router.push({ name: 'ofstock' })
		}
	}
}
</script>

<style lang="scss" scoped>
.my {
	background-color: #f2f5fc;
	height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;

	&-2,
	&-3 {
		margin: 15px auto 0;
	}

	&-3 {
		margin-bottom: 15px;
	}
}

// 提示合同签署弹框
.unsignBox {
	width: 100%;
	height: 100vh;
	background-color: rgba($color: #000000, $alpha: 0.5);
	position: fixed;
	left: 0;
	top: 0;
	.unsignMain {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		.unsignContent {
			width: 305px;
			padding: 23px 52px 30px;
			box-sizing: border-box;
			background-color: #fff;
			border-radius: 16px;
			.tipsIcon {
				width: 80px;
				height: 84px;
				margin-bottom: 24px;
			}
			.OfStockIcon {
				width: 180px;
				height: 104px;
				margin-bottom: 24px;
			}
			.tips {
				color: #000;
				font-size: 16px;
				font-weight: 400;
				line-height: 20px;
				margin-bottom: 41px;
			}
			.hints {
				color: #a9abaf;
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 1px;
			}
			.hintsOnclick {
				color: #a9abaf;
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 41px;
			}
			.btn {
				width: 200px;
				height: 40px;
				box-sizing: border-box;
				line-height: 40px;
				border: 1px solid #00a63a;
				border-radius: 100px;
				background-color: #fff;
				color: #00a63a;
				font-size: 16px;
				font-weight: 400;
				outline: none;
			}
		}
		.closeBtn {
			width: 38px;
			height: 38px;
			margin-top: 18px;
		}
	}
}
</style>
