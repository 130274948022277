<template>
  <div class="dividend">
    <div
      class="dividend-1"
      :style="{
        'background-image': `url(${require('@/assets/dividend/card.png')})`,
      }"
    >
      <div class="dividend-1-1">{{ userStockInfo.total_stock || 0 }}</div>
      <div class="dividend-1-2">可用分红券</div>
    </div>

    <van-cell
      class="dividend-2"
      :value="item.created_at"
      v-for="(item, index) in userStockInfo.detail_list"
      :key="index" @click="toLink(item)"
    >
      <template #title>
        <img :src="require('@/assets/dividend/bond.png')" />
        <span>{{ item.stock_num }}</span>
      </template>
    </van-cell>
  </div>
</template>

<script>
import { Cell } from "vant";
import { $get } from "@/utils/request.js";
export default {
  components: {
    vanCell: Cell,
  },
  data() {
    return {
      userStockInfo: {},
    };
  },
  created() {
    this.getUserStockInfo();
  },
  methods: {
    // 分红券信息
    getUserStockInfo() {
      $get("/home/getUserStockInfo").then((res) => {
        this.userStockInfo = res;
      });
	},
	
	// 跳转
	toLink(item) {
		this.$router.push({name: 'dividendDetail', query: { item: JSON.stringify(item), name: this.userStockInfo.realname}})
	}
  },
};
</script>

<style lang="scss" scoped>
.dividend {
  min-height: 100%;
  box-sizing: border-box;
  background: #f2f5fc;
  padding: 10px 0;

  &-1 {
    width: 355px;
    height: 110px;
    box-sizing: border-box;
    background-size: 100% 100%;
    margin: auto;
    padding: 28px 0 0 49px;

    > div {
      width: 70px;
      text-align: center;
      line-height: 100%;
      color: #ffffff;
    }

    &-1 {
      font-size: 30px;
    }

    &-2 {
      margin-top: 10px;
      font-size: 14px;
      opacity: 0.8;
    }
  }

  &-2 {
    padding: 5px 15px 5px 12px;
    margin-top: 10px;

    .van-cell__title {
      display: flex;
      align-items: center;

      img {
        width: 40px;
      }

      span {
        font-size: 20px;
        margin-left: 7px;
      }
    }

    .van-cell__value {
      font-size: 12px;
      line-height: 40px;
    }
  }
}
</style>