<template>
	<div
		class="card1"
		:style="{
			'background-image': `url(${require('@/assets/wallet/bg1.png')})`,
		}"
	>
		<div class="card1-1">
			<div class="card1-1-1">
				<div>累计总收益(元)</div>
				<div>{{ item.total_money }}</div>
			</div>
			<router-link :to="{ name: 'detailProfit' }" custom v-slot="{ navigate }">
				<div class="card1-1-2" @click="navigate">明细 ></div>
			</router-link>
		</div>

		<div class="card1-2">
			<div>今日收益(元)</div>
			<div></div>
			<div>本月收益(元)</div>
		</div>

		<div class="card1-3">
			<div>+{{ item.today_amount }}</div>
			<div></div>
			<div>{{ item.month_amount }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['item']
	};
</script>

<style lang="scss" scoped>
	.card1 {
		width: 358px;
		height: 193px;
		box-sizing: border-box;
		background-size: 100% 100%;
		padding: 20px 0 0 19px;
		font-size: 16px;
    margin-left: 0;
		color: #ffffff;

		&-1 {
			width: 311px;
			height: 61px;
      box-sizing: border-box;
			display: flex;
      justify-content: space-between;

			&-1 {
				// width: 263px;

				> div {
					line-height: 100%;
				}

				div:nth-of-type(2) {
					margin-top: 8px;
					font-size: 36px;
				}
			}

			&-2 {
				// width: 60px;
				display: flex;
				align-items: center;
				letter-spacing: 0.5px;
			}
		}

		&-2,
		&-3 {
			width: 299px;
			display: flex;
			justify-content: space-between;

			> div {
				flex: 1;
				text-align: center;
			}
		}

		&-2 {
			line-height: 17px;
			margin-top: 39px;
		}

		&-3 {
			font-size: 20px;
			line-height: 20px;
			margin-top: 8px;
		}
	}
</style>