<template>
  <div
    class="card2"
    :style="{
      'background-image': `url(${require('@/assets/wallet/bg2.png')})`,
    }"
  >
    <div class="l">总资产(元)</div>
    <div class="card2-2 l">{{ item.money }}</div>
    <div class="card2-3">
      <div @click="toCashOut">
        <img src="@/assets/wallet/cash.png" />
        <span>置换 ></span>
      </div>
      <router-link
        :to="{ name: 'detailWithdrawal' }"
        custom
        v-slot="{ navigate }"
      >
        <div @click="navigate">
          <img src="@/assets/wallet/detail.png" />
          <span>置换明细 ></span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { $get, $post } from "@/utils/request.js";
export default {
  props: ["item"],
  methods: {
    // 去置换页面
    toCashOut() {
		this.$router.push("/exchange");
    //   $get("/home/checkWithdraw").then((res) => {
		  // console.log(res)
    //     if (res.length) {
    //       // 去签约
    //       localStorage.setItem("wxLoginStatus", true);
    //       window.location.href = res;
    //     } else {
    //       if(this.item.bank_num) {
    //         // 有银行卡 - 去提现
    //         this.$router.push("/cashOut");
    //       }else {
    //         // 没有银行卡 - 去添加
    //         // this.$router.push({ name: 'myBankCard', params: { addBtnBol: this.item.bank_num != this.item.bank_max_num }})
    //       }
    //     }
    //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.card2 {
  width: 365px;
  height: 190px;
  box-sizing: border-box;
  background-size: 100% 100%;
  padding: 30px 0 0 19px;
  font-size: 16px;
  color: #ffffff;

  .l {
    line-height: 100%;
  }

  &-2 {
    margin-top: 8px;
    font-size: 36px;
  }

  &-3 {
    width: 311px;
    height: 20px;
    margin-top: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
    }

    img {
      width: 20px;
      height: 20px;
    }

    span {
      letter-spacing: 0.5px;
      margin-left: 8px;
      line-height: 20px;
    }
  }
}
</style>