<template>
  <div class="enterprise">
    <Card
      content="置换"
    />
    <div class="enterprise-1 w">
      <div>置换商品</div>
    </div>
    <van-form @submit="onSubmit">
      <van-cell title="商品" name="shop" :value="reportContent.eventName" @click="showPopup">
        </van-cell>
      <template v-for="(item, index) in info">
        <div class="enterprise-1 w" v-if="item.type === 'w'" :key="index">
          <div>{{ item.content }}</div>
        </div>

        <van-field
          v-else-if="item.type === 'select'"
          :name="item.name"
          :key="index"
          :rules="item.rules"
        ></van-field>
        

        <template v-else-if="item.type === 'popup'">
          <van-field
            :key="index"
            class="red"
            readonly
            :value="item.model"
            :name="item.name"
            required
            :label="item.label"
            placeholder="请选择"
            input-align="right"
            error-message-align="right"
            @click="item.showPicker = true"
            right-icon="arrow"
            :rules="item.rules"
          />
          <van-popup
            v-model="item.showPicker"
            position="bottom"
            :key="index + item.name"
          >
            <van-picker
              show-toolbar
              :loading="item.loading"
              :columns="item.columns"
              @confirm="onConfirm($event, index)"
              @cancel="item.showPicker = false"
            />
          </van-popup>
        </template>

        <van-field
          v-else-if="item.type === 'uploader'"
          class="uploader"
          :class="item.class"
          :name="item.name"
          :key="index"
          :rules="item.rules"
        >
          <template #label v-if="item.label">
            {{ item.label }}（
            <span style="color: #ff0000">{{
              item.num.reduce((o, t) => o + info[t].model.length, 0)
            }}</span>
            / {{ item.num.length }} ）
          </template>
          <template #input>
            <van-uploader v-model="item.model" :name="item.name">
              <div
                class="upLoad"
                :style="{
                  'background-image': `url(${item.url})`,
                }"
              >
                <div>{{ item.content }}</div>
              </div>
            </van-uploader>
          </template>
        </van-field>

        <van-field
          v-else
          :key="index"
          v-model="item.model"
          :class="item.class"
          :name="item.name"
          :label="item.label"
          :placeholder="item.placeholder"
          :type="item.type"
          required
          :input-align="item.align || 'right'"
          :error-message-align="item.align || 'right'"
          :rules="item.rules"
        />
      </template>
      <div class="group">
        <div class="left">
          <!-- <van-radio-group  class="radiogroup" v-model="checked">
            <van-radio ></van-radio>
          </van-radio-group> -->
          <van-checkbox checked-color="#00a63a" class="radiogroup" v-model="checked"></van-checkbox>
        </div>
        <div class="right"><p style=" text-align: right;">我已阅读并同意</p><p style=" text-align: left; color: #00a63a;" @click="dyDeselect">《置换服务事项提示》</p></div>
      </div>
      <van-button class="btn" round block type="info" native-type="submit">提交</van-button
      >
    </van-form>
    <van-popup 
    close-on-click-overlay
      round
      lock-scroll
      closeable
      class="popup" v-model="show">

      <Pdf/>
    </van-popup>

    <van-popup v-model="showRenWu" position="bottom" round>
      <van-picker
      show-toolbar
      :columns="renWuList"
      defaultIndex="1"
      @cancel="onCancel"
      @confirm="onConfirms"
      />
      </van-popup>
    <!-- <van-dialog v-model="show"><iframe src="http://share.422wsdq.com/upload/zhxy.pdf"></iframe></van-dialog> -->
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import Card from "./Card.vue";
import Pdf from "../pdf/pdfShow.vue";
// import Webview from "./Webview.vue";
import { Form, Field, Button, Popup, Picker, Uploader, Checkbox, Toast, Dialog ,DropdownMenu, DropdownItem,Cell } from "vant";
import { $get, $post } from "@/utils/request.js";
export default {
  name: "authenticationEnterprise",
  components: {
    vanForm: Form,
    vanField: Field,
    vanButton: Button,
    vanPopup: Popup,
    vanPicker: Picker,
    vanUploader: Uploader,
    vanCheckbox: Checkbox,
    Card,
    vanDialog: Dialog,
    Pdf,
    vanDropdownMenu:DropdownMenu,
    vanDropdownItem:DropdownItem,
    vanCell:Cell,
    // Webview,

  },
  data() {
    const defaultRules = [{ required: true, message: "" }];
    return {
      shop:'',
      reportContent:{eventName:'请选择要置换的商品 >'},
      showRenWu:false,
      renWuList: [ 
        { text: '凡食SOD代餐粉 30克*14袋（420克）￥288.00', value: 1 }, 
        { text: '甘油二脂SOD酵母固体饮料 5克*14袋（70克）￥398.00', value: 2 },
        { text: '沙棘SOD酵母饮品 50ml*14瓶（700ml）￥398.00', value: 3 }, 
        { text: '益生元SOD酵母固体饮料 30克*7袋（210克）￥198.00', value: 4 }, 
        { text: 'SOD套餐 ￥1980.00', value: 5 }, 
      ],
      dateShow: false, //控制地区选择组件显隐 
      areaList: areaList, //地区数据
      url: "http://share.422wsdq.com/upload/zhxy.pdf",
      urls: "<a href='http://share.422wsdq.com/upload/zhxy.pdf'>《置换服务事项提示》</>",
      show: false,
      checked: false,
      ischange: false,
      info: [
        {
          type: "number",
          model: "",
          name: "num",
          label: "置换数量",
          placeholder: "请输入置换数量",
          rules: defaultRules,
        },
        {
          type: "w",
          content: "收货人信息",
        },
        {
          type: "text",
          model: "",
          name: "name",
          label: "收货人姓名",
          placeholder: "请输入收货人姓名",
          rules: defaultRules,
        },
        {
          type: "text",
          model: "",
          name: "phone",
          label: "收货人电话",
          placeholder: "请输入收货人电话",
          rules: defaultRules,
        },
        {
          type: "textarea",
          model: "",
          name: "address",
          label: "收货地址",
          placeholder: "请输入收货地址",
          rules: defaultRules,
        },
        {
          type: "w",
          content: "发票信息（企业用户必须上传）",
        },
        {
          type: "uploader",
          model: [],
          name: "fp_img",
          rules: [
            {
              validator: () => {
                // return (
                //    this.info[13].model.length + this.info[14].model.length >= 2
                // );
              },
              message: "企业用户请上传发票信息",
              trigger: "onChange",
            },
          ],
          content: "企业用户请上传发票信息",
          url: require("@/assets/authentication/upLoad.png"),
          class: "uploader2 uploader3",
        },
      ],
    };
  },
  created() {
    // $get("/home/getBankList").then((res) => {
    //   this.info[6].columns = res.map((item) => ({
    //     bank_code: item.bank_code,
    //     text: item.bank_name,
    //   }));
    //   this.info[6].loading = false;
    // });

    // $get("/home/industry/list").then((res) => {
    //   this.info[11].columns = res.map((item) => ({
    //     id: item.id,
    //     text: item.industry_name,
    //   }));
    //   this.info[11].loading = false;
    // });
  },
  methods: {
    showPopup() {
                this.showRenWu = true;
            },
    onConfirms(event) {
        this.showRenWu = false;
        // console.log(event)
        this.reportContent.eventName = event.text
        this.reportContent.eventValue = event.value
        this.shop = event.value
        // console.log( this.reportContent.eventName)
        // const { picker, value, index } = event.detail;
      },
      onCancel() {
        this.showRenWu = false;
      },
    changmethod() {
      // this.checked = true
    },
    dyDeselect() {
       this.show = true
      // console.log('==' + this.checked)
      // window.open("http://share.422wsdq.com/upload/zhxy.pdf","_self");
      // alert(234234);
      // var url = "http://share.422wsdq.com/upload/zhxy.pdf";
      // window.location.$href = url;
      // location.href = "http://share.422wsdq.com/upload/zhxy.pdf";
    },
    onSubmit(values) {
      // values.bank_code = this.info[6].modelId;
      // values.industry_id = this.info[11].modelId;
      if (!this.checked) {
        this.$toast('请阅读并同意置换服务条款')
        return
      }
      values['shop'] = this.shop
      console.log(values)
      if(values.fp_img.length > 0){
        let count = 0;
      ["fp_img"].forEach((item) => {
        const formData = new FormData();
        if(values[item][0].file){
          formData.append("file", values[item][0].file);
        }

        $post("/uploadToHome", formData).then((res) => {
          values[item] = res[0].url;
          count++;

          count >= 1 &&
            $post("/home/user/apply", values).then(() => {
              this.$router.replace({ path: "/wallet/index" });
            });
        });
      });
      }else{
        values['fp_img'] = '';
        //直接提交
        $post("/home/user/apply", values).then(() => {
              this.$router.replace({ path: "/wallet/index" });
            });
      }
      
      
    },
    onConfirm(value, index) {
      if (typeof value === "object") {
        this.info[index].modelId = value.id || value.bank_code;
        this.info[index].model = value.text;
      } else {
        this.info[index].model = value;
      }
      this.info[index].showPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>

/*焦点时内容为空*/
.van-ellipsis:focus:before{
  content:none;
}
.memberGrade .van-ellipsis:empty:before{
  content: '请选择（必填）';   /* element attribute*/
  color: #C8C9CC;
}
.enterprise {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 10px;
  background: #f2f5fc;

  .w {
    background-color: #fff;
  }

  &-1 {
    padding: 15px;

    div {
      color: #000000;
      font-size: 20px;
      font-weight: bold;
      line-height: 100%;
      padding-left: 10px;
      border-left: 5px solid #33b761;
    }
  }

  .btn {
    width: 343px;
    height: 44px;
    font-size: 16px;
    color: #ffffff;
    // color: red;
    margin: 30px auto 28px;
    background: #00a63a;
    border: 0.026667rem solid #00a63a;
  }
  .group {
    width: 342px;
    height: 2vh;
    display: flex;
    margin: 30px auto 28px;
  }
  .left {
    width: 10%;
    height: 100%;
    text-align: right;
    position: relative;
  }
  .radiogroup {
    position: absolute;
    right: 5px;
    margin: 12 auto;
  }
  .right {
    display: flex;
    width: 90%;
    height: 20px;
    text-align: center;
    line-height: 20px;
  }
  .right >p {
    font-size: 15px;
  } 
  .popup {
    position: absolute;
    width: 342px;
    height: 500px;
  }  

  .upLoad {
    width: 342px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;

    div {
      width: 100%;
      position: absolute;
      font-size: 16px;
      color: #33b761;
      text-align: center;
      bottom: 35px;
    }
  }

  .upLoad2 {
    height: 200px;

    div {
      color: #808080;
      bottom: 24px;
    }
  }

  .uploader3 {
    padding-top: 0;
    margin-top: -11px;
  }
}
</style>