<template>
    <div class="pdf-content">
        <!-- 显示所有页 -->
        <div class="all-pdf" v-if="isShowAll">
            <pdf v-for="i in pageTotalNum" :key="i" :src="pdfUrl" :page="i"/>
        </div>

        <!-- 只显示第一页，进行翻页查看 -->
        <div class="change-page-pdf" v-else>
            <pdf ref="pdf"
            :src="pdfUrl"
            :page="currentPage"
            @progress="loadedRatio=$event"
            @page-loaded="pageLoaded($event)"
            @num-pages="pageTotalNum=$event"
            @link-clicked="page=$event"
            />
            {{currentPage}}/{{pageTotalNum}}
            <div class="btn-box">
                <span class="prev-btn" @click.stop="changePdfPage('prev')">上一页</span>
                <span class="next-btn" @click.stop="changePdfPage('next')">下一页</span>
            </div>
        </div>
    </div>
</template>
<script>
import pdf from "vue-pdf-signature";
import CMapReaderFactory from "vue-pdf-signature/src/CMapReaderFactory";
export default {
  props: {
    //是否显示所有页
    isShowAll: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    pdf,
  },
  data() {
    return {
      pdfUrl: "/zhxy.pdf", //pdf文件路径
      currentPage: 1, //当前页码
      pageTotalNum: 4, //总页数
      loadedRatio: 0, //pdf是否已加载完成 0:未完成  1:完成
    };
  },
  mounted() {
    this.pdfTask(this.pdfUrl);
  },
  methods: {
    /**
     * pdf初始化
     * @param {pdfUrl} string pdf路径 - 若为静态文件，需放在static文件夹下
     */
    pdfTask(pdfUrl) {
      const that = this;
      //该方法可以显示合同类型pdf中的印章、签名等，但字体较小的文案则无法显示
      var loadingTask = pdf.createLoadingTask(pdfUrl);

      //该方法可以完整显示文案，但无法显示合同类型pdf中的印章、签名
      var loadingTask = pdf.createLoadingTask({
        url: pdfUrl,
        CmapPacked: true,
        CMapReaderFactory,
      });

      loadingTask.promise
        .then((pdf) => {
          that.pdfUrl = loadingTask;
          that.pageTotalNum = pdf.numPages;
        })
        .catch((err) => {
          console.log("加载失败", err);
        });
    },
    /**
     * pdf加载
     */
    pageLoaded(e) {
      this.currentPage = e;
    },
    /**
     * 翻页
     * @param {pageOperation} 翻页类型 prev:上一页  next:下一页
     */
    changePdfPage(pageOperation) {
      let page = this.currentPage;
      if (pageOperation == "prev") {
        if (page == 1) return false;
        page--;
      }
      if (pageOperation == "next") {
        if (page == this.pageTotalNum) return false;
        page++;
      }
      this.currentPage = page;
    },
  },
};
</script>
<style lang="scss" scoped>
.pdf-content {
  width: 100%;
  .btn-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span {
      display: block;
      width: 50%;
      height: 44px;
      text-align: center;
      line-height: 44px;
      color: #333;
      background: #fff;
    }
    .next-btn {
      color: #fff;
      background: #fa5050;
    }
  }
}
</style>
