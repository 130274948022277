import { render, staticRenderFns } from "./Card2.vue?vue&type=template&id=766b53d9&scoped=true&"
import script from "./Card2.vue?vue&type=script&lang=js&"
export * from "./Card2.vue?vue&type=script&lang=js&"
import style0 from "./Card2.vue?vue&type=style&index=0&id=766b53d9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "766b53d9",
  null
  
)

export default component.exports