// 配置文件
// 环境变量
const ENV = process.env.NODE_ENV === 'production' ? 'prod' : 'dev'


console.log('process.env.NODE_ENV:', process.env.NODE_ENV)

console.log('当前开发环境:', ENV)

// 请求地址
const API_HOST = {
  // 本地联调测试地址 ---s
  dev: 'https://fsby-mobile.422wsdq.com/api', // 测试环境
	// dev: 'http://share.422wsdq.com/api', // 测试环境
  // 本地联调测试地址 ---e

  // 服务器地址 ---s
  // prod: 'http://share.422wsdq.com/api' // 正式环境
  prod: 'https://fsby-mobile.422wsdq.com/api' // 测试环境
  // 服务器地址 ---e
}



const config = {
  ENV,
  apiHost: API_HOST[ENV]
}

export default config
