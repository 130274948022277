var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"information"},[_c('UpLoad',{attrs:{"url":"/home/saveAvatar","parameter":"avatar"},on:{"finish":_vm.uploadFinish}},[_c('van-cell',{staticClass:"information-1",attrs:{"is-link":"","value":"修改头像","clickable":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('img',{attrs:{"src":_vm.userInfo.avatar}})]},proxy:true}])})],1),_c('van-cell',{staticClass:"information-2",attrs:{"is-link":"","title":"昵称","value":_vm.userInfo.nickname,"to":{
				name: 'informationRevise',
				params: {
					data: _vm.userInfo.nickname,
					url: '/home/saveName',
					key: 'nickname',
					method: _vm.nameMethod
				}
			}}}),_c('van-cell',{staticClass:"information-3",attrs:{"title":"手机号","value":_vm.userInfo.phone,"clickable":false}}),(!_vm.userInfo.icheck)?_c('van-cell',{staticClass:"information-2 red",attrs:{"is-link":"","border":false,"title":"实名","value":"还未实名，前往认证"},on:{"click":_vm.getCheckUserAudit}}):[_c('van-cell',{staticClass:"information-3",attrs:{"title":"实名","value":_vm.userInfo.realname,"clickable":false}}),_c('van-cell',{staticClass:"information-3",attrs:{"title":"身份证号码","value":_vm.userInfo.card_no,"clickable":false}})],(_vm.userInfo.audit_fail_reason !== '')?_c('van-cell-group',{staticStyle:{"margin-top":"50px"}},[_c('van-cell',{attrs:{"title":"","label":_vm.userInfo.audit_fail_reason}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }