<template>
	<div class="team-card">
		<van-cell
			class="team-card-1"
			:border="false"
			is-link
			title="团队数据"
			:to="{ name: 'myTeam' }"
		/>
		<div class="team-card-2">
			<div>
				<div class="count">{{ info.team }}</div>
				<div class="content">人数</div>
			</div>
			<div>
				<div class="count">{{ info.team_money }}</div>
				<div class="content">业绩</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Cell } from "vant";
	export default {
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
		components: {
			vanCell: Cell,
		},
	};
</script>

<style lang="scss" scoped>
	.team-card {
		width: 345px;
		height: 112px;
		background: #ffffff;
		border-radius: 12px;

		&-1 {
			padding: 11px 0;
			font-size: 16px;
			border-radius: 12px;

			span {
				margin-left: 15px;
				color: #000000;
			}

			i {
				margin-right: 10px;
			}
		}

		&-2 {
			margin-top: 8px;
			height: 41px;
			display: flex;

			> div {
				width: 172px;
				height: 100%;
				text-align: center;
			}

			> div:nth-of-type(1) {
				border-right: 1px solid #f2f5fc;
			}

			.count {
				font-size: 16px;
				line-height: 24px;
				color: #00a63a;
			}

			.content {
				font-size: 14px;
				color: #333333;
			}
		}
	}
</style>