<template>
	<div
		class="card"
		:style="{
			'background-image': `url(${require('@/assets/my/bg.png')})`,
		}"
	>
		<span>{{ content }}</span>
		<img :src="src" />
	</div>
</template>

<script>
	export default {
		props: {
			content: {
				type: String,
				default: "",
			},
			src: {
				type: String,
				default: "",
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 100%;
		height: 120px;
		background-position: center;
		background-size: 108% auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			font-size: 30px;
			color: #ffffff;
			margin-left: 38px;
		}

		img {
			width: 64px;
			margin-right: 41px;
		}
	}
</style>