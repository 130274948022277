<template>
	<div class="card">
		<img :src="info.avatar" />
		<div class="card-2">
			<div class="card-2-1">{{ info.realname }}</div>
			<div class="card-2-2">
				<div v-for="(item, index) in info.roles" :key="index">{{ item }}</div>
			</div>
		</div>
		<!-- <div class="card-3" @click="handleClick">
			<span>团队组织</span>
			<van-icon name="arrow" />
		</div> -->
	</div>
</template>

<script>
	import { Icon } from "vant";
	export default {
		components: {
			vanIcon: Icon,
		},
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
		methods: {
			handleClick() {
				if(this.info.user_group_id) {
					this.$router.push({ name: 'organizationTeam' });
				} else {
					this.$toast('未加入团队组织')
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 345px;
		height: 105px;
		box-sizing: border-box;
		padding: 18px 10px 18px 20px;
		background: linear-gradient(216deg, #ceb587 0%, #a78851 100%);
		box-shadow: 2px 2px 4px rgba(0, 70, 24, 0.2);
		border-radius: 15px;

		> img,
		> div {
			float: left;
		}

		img {
			width: 70px;
			height: 70px;
			border-radius: 50%;
		}

		&-2 {
			width: 130px;
			margin-left: 10px;
			padding-top: 15px;

			&-1 {
				font-size: 16px;
				color: #ffffff;
				line-height: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			&-2 {
				height: 27px;
				margin-top: 8px;
				overflow: scroll;
				white-space: nowrap;
				font-size: 0;
				-webkit-overflow-scrolling: touch;

				div {
					display: inline-block;
					line-height: 20px;
					background: #fff1d9;
					border-radius: 100px;
					padding: 0 12px;
					font-size: 12px;
					color: #a68650;
					margin-right: 10px;
				}

				div:nth-last-of-type(1) {
					margin: 0;
				}
			}
		}

		&-3 {
			margin-left: 7px;
			width: 98px;
			height: 100%;
			font-size: 16px;
			color: #ffffff;
			display: flex;
			align-items: center;

			i {
				top: 0.5px;
				margin-left: 6px;
			}
		}
	}
</style>