<template>
  <div class="order-detail">
    <div class="order-detail-1">
      <div>{{ statusList[info.status] }}</div>
      <div>订单编号：{{ info.order_no }}</div>
    </div>

    <div class="order-detail-2">
      <img
        class="order-detail-2-1"
        :src="info.detail ? info.detail.product_img : ''"
      />
      <div class="order-detail-2-2">
        <div>{{ info.detail ? info.detail.product_name : "" }}</div>
        <div>
          用户通过扫一扫屏幕二维码，免费获得可
          降解环保袋。公司获取流量，通过流量对 接广告。
        </div>
      </div>
    </div>
    <van-cell
      class="order-detail-3"
      :title="'￥' + (info.detail && info.detail.price)"
      :title-style="{ color: '#00A63A' }"
      :value="'x' + info.nums"
      :clickable="false"
    />

    <!-- <van-cell title="证件地址" value="中国广东省广州市" :clickable="false" />
    <van-cell title="详细地址" value="天河区珠江路8-1号" :clickable="false" /> -->
    <van-cell
      title="加盟商区域"
      :value="info.area_name"
      :clickable="false"
    />
    <van-cell
      title="推荐人"
      :value="info.parent_user_data && info.parent_user_data.realname"
      :clickable="false"
    />
    <van-cell
      title="购屏合同"
      :is-link="!info.contract_detail_status"
      :value="info.contract_detail_status ? '已签署' : ''"
      @click="handleSignContract"
    />
    <van-cell
      title="下单时间"
      :value="info.created_at"
      :clickable="false"
    />

    <div
      class="order-detail-4"
      v-if="!info.status || info.status == 5"
    >
      <van-cell
        :clickable="false"
        v-if="!info.pay_type"
      >
        <template #title>
          <img :src="require('@/assets/other/wechat.png')" />
          <span>微信支付</span>
        </template>
        <template #right-icon>
          <van-radio checked-color="#33b761" />
        </template>
      </van-cell>

      <van-cell
        :clickable="false"
        v-if="info.pay_type == 3"
      >
        <template #title>
          <img :src="require('@/assets/other/wallet.png')" />
          <span>线下支付</span>
        </template>
        <template #right-icon>
          <van-radio checked-color="#33b761" />
        </template>
      </van-cell>
    </div>

    <div
      class="order-detail-5"
      v-if="!info.status || info.status == 5"
    >
      <div class="order-detail-5-1">
        合计 <span>￥{{ info.detail && info.detail.total }}</span>
      </div>
      <div
        class="order-detail-5-2"
        v-loading="visible"
        @click="handlePay"
      >
        {{ info.contract_detail_status ? "现在支付" : "去签署" }}
      </div>
    </div>
  </div>
</template>

<script>
import { Cell, RadioGroup, Radio } from "vant";
import { $get, $post } from "@/utils/request.js";
import { $wxPay } from "@/utils/wxUtils.js";
export default {
  name: "orderDetail",
  components: {
    vanCell: Cell,
    vanRadio: Radio,
    vanRadioGroup: RadioGroup
  },
  data() {
    return {
      info: {
        detail: { price: 0 },
        parent_user_data: { realname: "" }
      },
      statusList: [
        "待付款",
        "待审核",
        "已完成",
        "已取消",
        "审核失败",
        "待签署"
      ],
      pay_voucher: null, // 凭证链接

      isClick: true // 是否可点击
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let id = this.$route.query.id;
      $get(`/home/order/detail/${id}`).then(res => {
        this.info = res;
      });
    },

    // 生成合同签署链接
    handleSignContract() {
      if (this.isClick) {
        this.isClick = false;
        $get(`/home/productOrder/orderContractSign/${this.info.id}`)
          .then(res => {
            if (res.redirect_url) {
              localStorage.setItem("wxLoginStatus", true);
              window.location.href = res.redirect_url;
            } else {
              this.$toast("已签署完成");
              this.getDetail();
            }
            this.isClick = true;
          })
          .catch(err => {
            this.isClick = true;
          });
      }
    },

    // 支付
    handlePay() {
      if (this.isClick) {
        this.isClick = false;
        if (!this.info.pay_type) {
          // 微信支付
          if (this.info.contract_detail_status) {
            // 已签署
            $get("/home/pay", {
              pay_type: 0,
              order_no: this.info.order_no
            }).then(res => {
              $wxPay(res.pay_info).then(res => {
                console.log(res, "-------------------");
                this.$router.back();
              });
                this.isClick = true;
            }).catch(err => {
                this.isClick = true;
              });
          } else {
            $get(`/home/productOrder/orderContractSign/${this.info.id}`)
              .then(res => {
                if (res.redirect_url) {
                  localStorage.setItem("wxLoginStatus", true);
                  window.location.href = res.redirect_url;
                } else {
                  // 微信支付
                  $get("/home/pay", {
                    pay_type: 0,
                    order_no: this.info.order_no
                  }).then(res => {
                    $wxPay(res.pay_info).then(res => {
                      console.log(res, "-------------------");
                      this.$router.back();
                    });
                  });
                }
                this.isClick = true;
              })
              .catch(err => {
                this.isClick = true;
              });
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.order-detail {
  min-height: 100%;
  background: #f2f5fc;
  box-sizing: border-box;
  padding-bottom: 107px;

  &-1 {
    background: #007127;
    padding: 25px 16px;

    div {
      line-height: 100%;
      color: #ffffff;
    }

    div:nth-of-type(1) {
      font-size: 18px;
    }

    div:nth-of-type(2) {
      margin-top: 12px;
      font-size: 14px;
    }
  }

  &-2 {
    margin-top: 10px;
    background-color: #ffffff;
    padding: 10px 17px 16px 16px;
    display: flex;
    justify-content: space-between;

    &-1 {
      width: 76px;
      height: 76px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &-2 {
      width: 252px;
      padding-top: 3px;

      div:nth-of-type(1) {
        font-size: 18px;
        color: #000000;
        line-height: 100%;
      }

      div:nth-of-type(2) {
        margin-top: 6px;
        font-size: 14px;
        color: #999999;
        line-height: 16px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  .van-cell {
    padding: 17px 15px;

    > div {
      line-height: 100%;
      font-size: 16px;
    }

    .van-cell__title {
      color: #808080;
    }

    .van-cell__value {
      color: #000000;
    }
  }

  &-3 {
    margin-bottom: 10px;

    .van-cell__value {
      font-size: 14px;
      color: #333333;
    }
  }

  &-4 {
    width: 345px;
    margin: 40px auto 0;
    border-radius: 10px;
    background: #ffffff;
    overflow: hidden;

    .van-cell {
      padding: 15px 13px;

      .van-cell__title {
        display: flex;
        align-items: center;

        img {
          width: 24px;
        }

        span {
          font-size: 16px;
          margin-left: 6px;
          color: #000000;
        }
      }
    }
  }

  &-5 {
    width: 375px;
    height: 66px;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 11px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-1 {
      font-size: 20px;
      color: #333333;

      span {
        color: #00a63a;
      }
    }

    &-2 {
      width: 140px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      background: #00a63a;
      border-radius: 109px;
      font-size: 16px;
      color: #ffffff;
    }
  }
}
</style>