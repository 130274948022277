<template>
  <div class="card">
    <div class="card-1">
      <div class="title">
        <img :src="require('@/assets/other/logo.png')" />
        <span>{{ item.title }}</span>
      </div>
      <div
        class="value"
        :class="{ col_aa: item.status == 2 || item.status == 3 }"
      >
        {{ statusList[item.status] }}
      </div>
    </div>

    <div class="card-2">
      <img :src="item.detail.product_img" />
      <div class="card-2-2">
        <div class="card-2-2-1">{{ item.detail.product_name }}</div>
        <div class="card-2-2-2">扫二维码，可免费领取环保袋</div>
        <div class="card-2-2-3">
          <span>￥{{ item.detail.total }}</span>
          <span>x{{ item.nums }}</span>
        </div>
      </div>
    </div>

    <div
      class="btn"
      v-if="!item.status"
    >
      <span @click.stop="handlePay">现在支付</span>
    </div>

    <div
      class="btn"
      v-if="item.status == 5"
    >
      <span @click.stop="handleSignContract">去签署</span>
    </div>
  </div>
</template>

<script>
import { $get, $post } from "@/utils/request.js";
import { $wxPay } from "@/utils/wxUtils.js";
export default {
  props: ["item"],
  data() {
    return {
      statusList: [
        "待付款",
        "待审核",
        "已完成",
        "已取消",
        "审核失败",
        "待签署"
      ],
      isClick: true // 是否可点击
    };
  },
  methods: {
    handlePay() {
      if (this.isClick) {
        this.isClick = false;
        // 微信支付
        $get("/home/pay", {
          pay_type: 0, // 0-微信支付，3-线下支付
          order_no: this.item.order_no // 订单编号
        })
          .then(res => {
            $wxPay(res.pay_info).then(res => {
              this.$router.push({
                name: "myOrder",
                params: { page: "all" }
              });
            });
            this.isClick = true;
          })
          .catch(err => {
            this.isClick = true;
          });
      }
    },

    // 生成合同签署链接
    handleSignContract() {
      if (this.isClick) {
        this.isClick = false;
        $get(`/home/productOrder/orderContractSign/${this.item.id}`)
          .then(res => {
            if (res.redirect_url) {
              localStorage.setItem("wxLoginStatus", true);
              window.location.href = res.redirect_url;
            } else {
              this.$toast("已签署完成");
              this.getDetail();
            }
            this.isClick = true;
          })
          .catch(err => {
            this.isClick = true;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 375px;
  // height: 135px;
  background-color: #fff;

  &-1 {
    width: 375px;
    height: 34px;
    box-sizing: border-box;
    padding: 5px 17px 5px 14px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;

    > div {
      flex: 1;
    }

    .title {
      display: flex;
      align-items: center;

      img {
        width: 24px;
      }

      span {
        margin-left: 6px;
        color: #000000;
      }
    }

    .value {
      text-align: right;
      color: #ff7643;
    }
    .value.col_aa {
      color: #aaaaaa;
    }
  }

  &-2 {
    width: 375px;
    height: 100px;
    box-sizing: border-box;
    padding: 12px 17px 12px 15px;
    display: flex;
    justify-content: space-between;

    img {
      width: 76px;
      height: 76px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &-2 {
      width: 252px;
      padding-top: 4px;

      > div {
        line-height: 100%;
      }

      &-1,
      &-2 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-1 {
        font-size: 16px;
        color: #333333;
      }

      &-2 {
        margin-top: 6px;
        font-size: 14px;
        color: #999999;
      }

      &-3 {
        width: 252px;
        height: 14px;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 14px;
        }

        span:nth-of-type(1) {
          color: #00a63a;
        }

        span:nth-of-type(2) {
          color: #333333;
        }
      }
    }
  }

  .btn {
    width: calc(100% - 20px);
    box-sizing: border-box;
    padding: 9px 7px;
    border-top: 1px solid #dddddd;
    text-align: right;
    margin: auto;
    span {
      width: 84px;
      height: 32px;
      box-sizing: border-box;
      line-height: 32px;
      border-radius: 6px;
      background-color: #00a63a;
      display: inline-block;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
}
</style>