var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"information-team"},[_c('UpLoad',{attrs:{"url":"/home/editUserGroupAvatar","parameter":"group_avatar"},on:{"finish":_vm.handleFinish}},[_c('van-cell',{staticClass:"information-team-1",attrs:{"is-link":"","value":"修改头像","clickable":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('img',{attrs:{"src":_vm.userGroupData.group_avatar}})]},proxy:true}])})],1),_c('van-cell',{staticClass:"information-team-2",attrs:{"is-link":"","title":"昵称","value":_vm.userGroupData.group_user_data && _vm.userGroupData.group_user_data.realname,"to":{
			name: 'informationRevise',
			params: {
				data: _vm.userGroupData.group_user_data.realname,
				url: '/home/editUserGroupName',
				key: 'group_name',
				method: _vm.group_nameMehtod,
			},
		}}}),_c('van-cell',{staticClass:"information-team-2",attrs:{"is-link":"","title":"签名","value":_vm.userGroupData.group_sign,"to":{
			name: 'informationRevise',
			params: {
				data: _vm.userGroupData.group_sign,
				url: '/home/editUserGroupSign',
				key: 'group_sign',
				method: _vm.group_signMehtod,
			},
		}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }