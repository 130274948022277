<template>
	<div class="sell-team">
		<Card1 :info="info" />
		<!-- <Card2 :info="info" class="card2" /> -->
	</div>
</template>

<script>
	import Card1 from "./card1";
	import Card2 from "./card2";
	import { $get } from "@/utils/request.js";
	export default {
		components: {
			Card1,
			Card2,
		},
		data() {
			return {
				info: {},
			};
		},
		created() {
			$get("/home/getUserTeamData").then((res) => {
				this.info = res;
			});
		},
	};
</script>

<style lang="scss" scoped>
	.sell-team {
		min-height: 100%;
		background: #f2f5fc;

		.card2 {
			margin: 15px auto 0;
		}
	}
</style>