<template>
	<div class="my-swipe">
		<van-swipe :autoplay="3000" :show-indicators="false" @change="handleChange">
			<van-swipe-item
				v-for="item in list"
				:key="item.id"
				:style="{
					'background-image': `url(${item.main_img})`,
				}"
			>
				<div class="cateNmae">{{ item.name }}</div>
				<router-link
					class="details"
					:to="{ name: 'detail', params: { id: item.id } }"
					>查看详情</router-link
				>
			</van-swipe-item>
		</van-swipe>
		<div class="indicators">
			<div
				v-for="(item, index) in list"
				:key="item.id"
				:class="swipeIndex === index && 'click'"
			></div>
		</div>

		<van-popup v-model="show" class="popup">
			<div class="popup-1">
				<img class="popup-1-1" :src="require('@/assets/other/remind1.png')" />
				<div class="popup-1-2">设备已铺装完成，请完成商品签收</div>
				<router-link
					:to="{ name: 'paving', params: { page: 'sign' } }"
					custom
					v-slot="{ navigate }"
				>
					<div class="popup-1-3" @click="navigate">交付签单</div>
				</router-link>
			</div>
			<img
				class="popup-2"
				@click="show = false"
				:src="require('@/assets/other/remind2.png')"
			/>
		</van-popup>
	</div>
</template>

<script>
	import { SwipeItem, Swipe, Popup } from "vant";
	import { $get } from "@/utils/request.js";
	export default {
		name: "home",
		components: {
			vanSwipeItem: SwipeItem,
			vanPopup: Popup,
			vanSwipe: Swipe,
		},
		data() {
			return {
				swipeIndex: 0,
				list: [],
				show: false,
			};
		},
		created() {
			$get("/home/product/list").then((res) => {
				this.list = res;
			});

			if(localStorage.getItem("token")) {
				console.log('=====' + '有token')
				if(!this.$store.state.checkUpMachineUnfinish) {
					this.$store.commit('setCheckUpMachineUnfinish');
					$get("/home/machine/checkUpMachineUnfinish").then((res) => {
						res.machine_unfinish > 0 && (this.show = true);
					});
				}
			}

		},
		methods: {
			handleChange(index) {
				this.swipeIndex = index;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.my-swipe {
		margin: auto;
		width: 315px;
		max-height: 582px;
		height: 86.60714285714286%;
		padding-top: 18px;
		position: relative;

		.van-swipe {
			width: 100%;
			height: 100%;
		}

		.van-swipe-item {
			box-sizing: border-box;
			border: 2px solid rgba(164, 200, 176, 0.2);
			border-radius: 16px;
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				#d1d7e3 100%
			);
			background-color: rgba(164, 200, 176, 0.2);
			background-size: cover;
			background-position: center 10%;
			background-clip: content-box;

			.cateNmae {
				font-size: 20px;
				font-weight: bold;
				position: absolute;
				top: 14px;
				left: 14px;
			}

			.details {
				width: 200px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: #00a63a;
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
				border-radius: 100px;

				font-size: 16px;
				color: #ffffff;

				position: absolute;
				bottom: 30px;
				left: 0;
				right: 0;
				margin: auto;
			}
		}

		.indicators {
			height: 10px;
			margin-top: 20px;
			display: flex;
			justify-content: center;

			div {
				width: 10px;
				height: 100%;
				border-radius: 5px;
				background-color: #f2f5fc;
				margin-right: 12px;
				box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
				transition: 0.4s;
			}

			.click {
				width: 26px;
				background: #00a63a;
			}

			:nth-last-of-type(1) {
				margin: 0;
			}
		}

		.popup {
			width: 305px;
			height: 318px;
			text-align: center;
			background: none;

			&-1 {
				width: 305px;
				height: 258px;
				box-sizing: border-box;
				padding-top: 21px;
				background-color: #fff;
				border-radius: 16px;

				&-1 {
					width: 90px;
				}

				&-2 {
					line-height: 100%;
					font-size: 16px;
					margin-top: 20px;
				}

				&-3 {
					width: 200px;
					height: 40px;
					line-height: 38px;
					box-sizing: border-box;
					border: 1px solid #00a63a;
					border-radius: 100px;
					font-size: 16px;
					color: #00a63a;
					margin: 41px auto 0;
				}
			}

			&-2 {
				width: 38px;
				margin-top: 18px;
			}
		}
	}
</style>