<template>
    <div class="detail-withdrawal">
      <div class="detail-withdrawal-1">
        <div class="detail-withdrawal-1-1" @click="datetimeBol = true">
          <div>{{ month.replace('-', '年') }}月</div>
          <div></div>
        </div>
        <div class="detail-withdrawal-1-2">
          <div>本月置换 : {{ cashTotalAmount }}</div>
        </div>
      </div>
      <div @click="dianji(item)" v-for="(item, index) in withdrawLog" :key="index" style="border-bottom: 2px solid #000000">
        <div class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>订单号：{{ item.trade_no }}</div>
          </div>
        </div>  
        <div class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>商品：{{ item.shop_name }}</div>
          </div>
        </div>
        <div class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>数量：{{ item.num }}</div>
          </div>
        </div>
        <div class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>单价：{{ item.price }}</div>
          </div>
        </div>
        <div class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>总价：{{ item.money }}</div>
          </div>
        </div>
        <div class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>收货人：{{ item.name }}</div>
          </div>
        </div>
        <div class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>收货人手机：{{ item.phone }}</div>
          </div>
        </div>
        <div class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>收货地址：{{ item.address }}</div>
          </div>
        </div>
        <div v-if="item.deliver_status > 0" class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>快递公司：{{ item.express_company }}</div>
          </div>
        </div>
        <div v-if="item.deliver_status > 0" class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>快递单号：{{ item.express_no }}</div>
          </div>
        </div>
        <div v-if="item.deliver_status > 0" class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div>发货时间：{{ item.consignor_time }}</div>
          </div>
        </div>
        <div class="detail-withdrawal-2">
          <div class="detail-withdrawal-2-1">
            <!-- <div>收益提现-到{{ item.bank_name }}({{ item.short_bank_account }})</div> -->
            <div><span :class="`col_${item.status}`">{{ item.status_name }}</span> {{ item.created_at_str }}</div>
          </div>
        </div>
      </div>
      
  
      <van-popup v-model="datetimeBol" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="选择年月"
          :max-date="maxDate"
          @confirm="datetimeConfirm"
          @cancel="datetimeCancel"
        />
      </van-popup>
    </div>
  </template>
  
  <script>
  import { Popup, DatetimePicker,Dialog } from "vant";
  import { $get } from "@/utils/request.js";
  import { formatDate } from "@/utils/util.js";
  export default {
    name: "detailWithdrawal",
    components: {
      vanPopup: Popup,
      vanDatetimePicker: DatetimePicker,
      vanDialog: Dialog,
    },
    data() {
      return {
        month: "", // 年月接口传参
        datetimeBol: false,
        currentDate: new Date(), // 当前年月
        maxDate: new Date(), // 最大月份
  
        withdrawLog: [], // 提现明细
        cashTotalAmount: 0, // 总提现金额
      };
    },
    created() {
        this.getWthdrawLog()
    },
    methods: {
      // 获取提现明细
      getWthdrawLog() {
          this.month = formatDate(this.currentDate, "yyyy-MM");
          $get('/home/user/displace', {
              month: this.month
          }).then(res => {
              this.withdrawLog = res.list
              this.cashTotalAmount = res.cash_total_amount
          })
      },
      //获取订单详情
      dianji(){
        
      },
      
      // 年/月选择器 取消
      datetimeCancel() {
        this.currentDate = new Date(this.month);
        this.datetimeBol = false;
      },
  
      // 年/月选择器 确认
      datetimeConfirm(e) {
        this.currentDate = e;
        this.datetimeBol = false;
        this.getWthdrawLog();
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .detail-withdrawal {
    min-height: 100%;
    padding-bottom: 15px;
    box-sizing: border-box;
    background: #f2f5fc;
  
    > div {
      background-color: #fff;
    }
  
    &-1 {
      padding: 15px;
  
      > div {
        line-height: 100%;
      }
  
      &-1 {
        display: flex;
        align-items: center;
        font-size: 16px;
  
        div:nth-last-of-type(1) {
          width: 7px;
          height: 7px;
          margin-left: 8px;
          transform: translateY(-3px) rotateZ(45deg);
          border-right: 2px solid #000000;
          border-bottom: 2px solid #000000;
        }
      }
  
      &-2 {
        margin-top: 8px;
        font-size: 13px;
        color: #808080;
      }
    }
  
    &-2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 5px 7px 5px;
      margin-bottom: 1px;
  
      div {
        line-height: 100%;
      }
  
      &-1 {
        div:nth-of-type(1) {
          font-size: 16px;
        }
  
        div:nth-of-type(2) {
          margin-top: 8px;
          font-size: 14px;
          color: #c8c8c8;
        }
        .col_0, .col_1, .col_0 {
          color: #198EED;
        }
        .col_8,.col_4 {
          color: #00A63A;
        }
        .col_7, .col_9, .col_2, .col_3 {
          color: #F42B2B;
        }
      }
  
      &-2 {
        font-size: 20px;
      }
    }

    &-4 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 11px 17px 15px;
      margin-bottom: 1px;
  
      div {
        line-height: 100%;
      }
  
      &-1 {
        div:nth-of-type(1) {
          font-size: 16px;
        }
  
        div:nth-of-type(2) {
          margin-top: 8px;
          font-size: 14px;
          color: #c8c8c8;
        }
        .col_0, .col_1, .col_0 {
          color: #198EED;
        }
        .col_8,.col_4 {
          color: #00A63A;
        }
        .col_7, .col_9, .col_2, .col_3 {
          color: #F42B2B;
        }
      }
  
      &-4 {
        font-size: 20px;
      }
    }
  
    &-2:nth-last-of-type(1) {
      margin: 0;
    }
  }
  </style>