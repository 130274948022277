<template>
	<div class="team">
		<!-- <RankingCard class="mb15" :info="info.user_ranking" /> -->
		<!-- <EquipmentCard class="mb10" :info="info.machine_data" /> -->
		<!-- <van-cell
			class="team-1 mb10"
			:border="false"
			is-link
			:to="{
				name: 'myStore',
				params: { page: 'already', shop_data: info.shop_data },
			}"
			title="铺装地"
		/> -->
		<TeamCard
			class="mb10"
			:info="{
				team: info.team,
				team_money: info.team_money,
			}"
		/>
		<van-cell
			class="team-1 mb10"
			:border="false"
			is-link
			title="加盟申请"
			:to="{ name: 'joinTeam' }"
		/>
		<van-cell
			class="team-1 mb10"
			:border="false"
			is-link
			title="店铺申请"
			:to="{ name: 'shopTeam' }"
		/>
		<!-- <van-cell
			class="team-1 mb10"
			:border="false"
			is-link
			title="我的设备"
			:to="{ name: 'myEquipment' }"
		/> -->
	</div>
</template>

<script>
	import { $get } from "@/utils/request.js";
	import { Cell } from "vant";
	// import RankingCard from "./RankingCard.vue";
	import EquipmentCard from "./EquipmentCard.vue";
	import TeamCard from "./TeamCard";
	export default {
		name: "my",
		components: {
			vanCell: Cell,
			// RankingCard,
			EquipmentCard,
			TeamCard,
		},
		data() {
			return {
				info: {},
			};
		},
		created() {
			$get("/home/getSaleData").then((res) => {
				this.info = res;
			});
		},
	};
</script>

<style lang="scss" scoped>
	.team {
		padding: 31px 15px 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		background-color: #f2f5fc;

		.mb15 {
			margin-bottom: 15px;
		}

		.mb10 {
			margin-bottom: 10px;
		}

		&-1 {
			padding: 13px 0;
			font-size: 16px;
			border-radius: 12px;

			span {
				margin-left: 15px;
				color: #000000;
			}

			i {
				margin-right: 10px;
			}
		}
	}
</style>